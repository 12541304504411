import { ReactElement } from 'react';

import Card, { ICardProps } from '^pages/Home/Cards/Card';
import texts from '^configs/texts';
import LocationOnFilledIcon from '^icons/LocationOnFilledIcon';
import EventNoteIcon from '^icons/EventNoteIcon';
import HowToRegIcon from '^icons/HowToRegIcon';
import PlateNumberIcon from '^icons/PlateNumberIcon';

import styles from './styles.module.scss';

const cards: Omit<ICardProps, 'index'>[] = [
  {
    Icon: PlateNumberIcon,
    description: texts.pages.home.cards.plateNumber,
  },
  {
    Icon: LocationOnFilledIcon,
    description: texts.pages.home.cards.location,
  },
  {
    Icon: EventNoteIcon,
    description: texts.pages.home.cards.eventNote,
  },
  {
    Icon: HowToRegIcon,
    description: texts.pages.home.cards.howToReg,
  },
];

const Cards = (): ReactElement => (
  <div className={styles.cards}>
    {cards.map(({ description, Icon }: Omit<ICardProps, 'index'>, index: number): ReactElement => (
      <Card
        key={index}
        index={index + 1}
        Icon={Icon}
        description={description}
      />
    ))}
  </div>
);

export default Cards;
