import { Update } from 'history';
import { ReactElement, useState, useLayoutEffect, ReactNode } from 'react';
import { Router as RouterBase } from 'react-router';

import sharedHistory from '../sharedHistory';

export interface IRouterProps {
  children: ReactNode;
}

const Router = ({ children }: IRouterProps): ReactElement => {
  const [state, setState] = useState<Update>(sharedHistory);

  useLayoutEffect(
    (): (() => void) => sharedHistory.listen(setState),
    [],
  );

  return (
    <RouterBase
      children={children}
      navigator={sharedHistory}
      {...state}
    />
  );
};

export default Router;
