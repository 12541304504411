import React, { Component, ReactNode } from 'react';

import logger from '^utils/logger';
import ErrorProvider from '^components/ErrorProvider';

export interface IErrorBoundaryProps {
  children: ReactNode;
}

interface IErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<IErrorBoundaryProps, IErrorBoundaryState> {
  public state: IErrorBoundaryState = {
    hasError: false,
  };

  public static getDerivedStateFromError(): IErrorBoundaryState {
    return {
      hasError: true,
    };
  }

  public componentDidCatch(error: Error, info: Record<string, any>): void {
    logger.trackCriticalError(error, {
      info,
      catcher: 'ErrorBoundary',
    });
  }

  public render(): ReactNode {
    const { children } = this.props;
    const { hasError } = this.state;

    return (
      <ErrorProvider hasError={hasError}>
        {children}
      </ErrorProvider>
    );
  }
}

export default ErrorBoundary;
