import { ReactElement } from 'react';
import { Typography } from '@interdan/public-ui';

interface ITitleProps {
  label: string;
  className?: string;
}

const Title = ({ label, className }: ITitleProps): ReactElement => (
  <Typography
    variant="h1"
    className={className}
  >
    {label}
  </Typography>
);

export default Title;
