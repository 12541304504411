import React, { ReactElement, ReactNode } from 'react';

import ErrorPage from '^components/ErrorPage';

interface IErrorProviderProps {
  hasError: boolean;
  children: ReactNode | ReactNode[];
}

const ErrorProvider = ({ hasError, children }: IErrorProviderProps): ReactElement => hasError
  ? <ErrorPage />
  : (
    <>
      {children}
    </>
  );

export default ErrorProvider;
