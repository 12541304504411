import { ReactElement } from 'react';

import texts from '^configs/texts';

import styles from './styles.module.scss';

const Bullets = (): ReactElement => (
  <ul className={styles.ul}>
    {texts.pages.aboutUs.bullets.map((bullet: string): ReactElement => (
      <li key={bullet}>
        {bullet}
      </li>
    ))}
  </ul>
);

export default Bullets;
