import { ReactElement } from 'react';

import texts from '^configs/texts';
import StaticPageTemplate from '^components/common/StaticPageTemplate';
import StaticSection from '^components/common/StaticSection';
import ISectionItem from '^types/ISectionItem';

const DataPolicy = (): ReactElement => (
  <StaticPageTemplate title={texts.pages.dataPolicy.title}>
    {texts.pages.dataPolicy.sections.map(({ text, title }: ISectionItem): ReactElement => (
      <StaticSection
        key={title}
        title={title}
      >
        {text}
      </StaticSection>
    ))}
  </StaticPageTemplate>
);

export default DataPolicy;
