import React, { ReactElement } from 'react';

import styles from './styles.module.scss';

const PlateNumberIcon = (): ReactElement => (

  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
  >
    <path
      d="M3.5 6.5V25.5H28.5V6.5H3.5ZM3 4C1.89543 4 1 4.89543 1 6V26C1 27.1046 1.89543 28 3 28H29C30.1046 28 31 27.1046 31 26V6C31 4.89543 30.1046 4 29 4H3Z"
      className={styles.accent}
    />

    <path
      d="M10.9202 20L9.49023 17.85L8.23023 20H6.29023L8.54023 16.43L6.24023 12.98H8.23023L9.64023 15.1L10.8802 12.98H12.8202L10.5902 16.52L12.9102 20H10.9202Z"
      className={styles.accent}
    />

    <path
      d="M19.7887 12.98L17.3587 17.68V20H15.6487V17.68L13.2187 12.98H15.1587L16.5187 15.92L17.8687 12.98H19.7887Z"
      className={styles.accent}
    />

    <path
      d="M22.2977 18.6H25.3177V20H20.3577V18.7L23.3577 14.38H20.3577V12.98H25.3177V14.28L22.2977 18.6Z"
      className={styles.accent}
    />
  </svg>

);

export default PlateNumberIcon;
