import React, { ReactElement } from 'react';

import styles from './styles.module.scss';

const HowToRegIcon = (): ReactElement => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
  >
    <path
      d="M12.5 22.6666L16.5 18.7466C15.98 18.6933 15.5933 18.6666 15.1667 18.6666C11.6067 18.6666 4.5 20.4533 4.5 23.9999V26.6666H16.5L12.5 22.6666ZM15.1667 15.9999C18.1133 15.9999 20.5 13.6133 20.5 10.6666C20.5 7.71992 18.1133 5.33325 15.1667 5.33325C12.22 5.33325 9.83333 7.71992 9.83333 10.6666C9.83333 13.6133 12.22 15.9999 15.1667 15.9999ZM21.1267 27.3333L16.5 22.6666L18.3667 20.7866L21.1267 23.5599L27.9667 16.6666L29.8333 18.5466L21.1267 27.3333Z"
      className={styles.accent}
    />
  </svg>
);

export default HowToRegIcon;
