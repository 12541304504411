import { FC, ReactElement } from 'react';
import { Typography } from '@interdan/public-ui';

import styles from './styles.module.scss';

export interface ICardProps {
  index: number;
  Icon: FC;
  description: string;
}

const Card = ({ Icon, description, index }: ICardProps): ReactElement => (
  <div className={styles.card}>
    <div className={styles.iconContainer}>
      <Icon />

      <Typography
        variant="caption2"
        className={styles.dot}
      >
        {index}
      </Typography>
    </div>

    <Typography
      variant="caption1"
      className={styles.description}
    >
      {description}
    </Typography>
  </div>
);

export default Card;
