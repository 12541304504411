import { ReactElement, useEffect, useRef } from 'react';

import { CONNECTION_SCRIPT_PATH } from '^configs/environments';
import RoutePath from '^enums/RoutePath';

import styles from './styles.module.scss';

const { origin } = window.location;

const ServiceBookerWidget = (): ReactElement => {
  const ref = useRef<HTMLDivElement>(null);

  const privacyPolicyUrl = `${origin}/${RoutePath.DataPolicy}`;

  useEffect(
    (): (() => void) | undefined => {
      const script = document.createElement('script');

      if (!ref?.current) {
        return;
      }

      const container = ref.current;

      script.src = CONNECTION_SCRIPT_PATH;

      const loadEventListener = (): void => {
        window.renderServiceBookerWidget(
          {
            theme: 'default',
            privacyPolicyUrl,
            isSpacersAutoCalculationAllowed: true,
            fixedElementsSelectors: ['header'],
          },
          '#service-booker-widget',
          true,
        );
      };

      if (container) {
        container.appendChild(script);

        script.addEventListener('load', loadEventListener);
      }

      return (): void => {
        script.removeEventListener('load', loadEventListener);
        container?.removeChild(script);

        window.zoid?.destroy();
      };
    },
    [privacyPolicyUrl, ref],
  );

  return (
    <div
      ref={ref}
      className={styles.container}
      id="service-booker-widget"
    />
  );
};

export default ServiceBookerWidget;
