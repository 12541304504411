import { ReactElement } from 'react';

import texts from '^configs/texts';
import StaticPageTemplate from '^components/common/StaticPageTemplate';
import StaticSection from '^components/common/StaticSection';
import ISectionItem from '^types/ISectionItem';
import Bullets from '^pages/AboutUs/Bullets';

const AboutUs = (): ReactElement => (
  <StaticPageTemplate title={texts.pages.aboutUs.title}>
    {texts.pages.aboutUs.sections.map(({ text, title }: ISectionItem): ReactElement => (
      <StaticSection
        key={title}
        title={title}
      >
        {text}
      </StaticSection>
    ))}

    <StaticSection title={texts.pages.aboutUs.brandsTitle}>
      <Bullets />
    </StaticSection>

    <StaticSection title={texts.pages.aboutUs.behindServiceBookerSectionTitle}>
      {texts.pages.aboutUs.behindServiceBookerSectionDescription}
    </StaticSection>

    <StaticSection>
      {texts.pages.aboutUs.behindServiceBookerSectionDescriptionSecondPart}
    </StaticSection>
  </StaticPageTemplate>
);

export default AboutUs;
