import { Action, createBrowserHistory, Update } from 'history';

const sharedHistory = createBrowserHistory();

sharedHistory.listen(({ action } : Update): void => {
  /**
   * Reset window scroll position to the top when changing route @its
   */
  if (action !== Action.Pop) {
    window.scrollTo(0, 0);
  }
});

export default sharedHistory;
