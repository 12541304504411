import { ReactElement, ReactNode } from 'react';
import { Typography } from '@interdan/public-ui';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface IStaticSectionProps {
  title?: string;
  titleClassName?: string;
  children: ReactNode;
  className?: string;
}

const StaticSection = ({ className, title, children, titleClassName }: IStaticSectionProps): ReactElement => (
  <div className={className}>
    {title && (
      <Typography
        variant="caption1"
        className={classNames(styles.title, titleClassName)}
      >
        {title}
      </Typography>
    )}

    <Typography
      variant="caption1"
      className={styles.text}
    >
      {children}
    </Typography>
  </div>
);

export default StaticSection;
