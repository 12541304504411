import { ReactElement } from 'react';
import { Typography } from '@interdan/public-ui';

import texts from '^configs/texts';
import ContentContainer from '^components/common/ContentContainer';

import styles from './styles.module.scss';

const MainScreen = (): ReactElement => (
  <section className={styles.section}>
    <ContentContainer>
      <div className={styles.content}>
        <Typography
          variant="h1"
          className={styles.header}
        >
          {texts.pages.home.header}
        </Typography>

        <Typography
          variant="subtitle1"
          className={styles.title}
        >
          {texts.pages.home.title}

          <Typography
            className={styles.description}
            variant="caption1"
          >
            {texts.pages.home.description}
          </Typography>
        </Typography>
      </div>
    </ContentContainer>
  </section>
);

export default MainScreen;
