import React, { ReactElement } from 'react';

import styles from './styles.module.scss';

const EventNoteIcon = (): ReactElement => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.1667 13.3333H9.83333V15.9999H23.1667V13.3333ZM25.8333 3.99992H24.5V1.33325H21.8333V3.99992H11.1667V1.33325H8.5V3.99992H7.16667C5.68667 3.99992 4.51333 5.19992 4.51333 6.66659L4.5 25.3333C4.5 26.7999 5.68667 27.9999 7.16667 27.9999H25.8333C27.3 27.9999 28.5 26.7999 28.5 25.3333V6.66659C28.5 5.19992 27.3 3.99992 25.8333 3.99992ZM25.8333 25.3333H7.16667V10.6666H25.8333V25.3333ZM19.1667 18.6666H9.83333V21.3333H19.1667V18.6666Z"
      className={styles.accent}
    />
  </svg>

);

export default EventNoteIcon;
