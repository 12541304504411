import { ReactElement } from 'react';
import { Typography } from '@interdan/public-ui';

import ContentContainer from '^components/common/ContentContainer';
import texts from '^configs/texts';
import { navigationItems } from '^components/common/Layout/configs';
import INavigationItem from '^types/INavigationItem';
import RouteAwareLink from '^components/common/RouteAwareLink';

import Logo from '../Logo';

import styles from './styles.module.scss';

const Footer = (): ReactElement => (
  <footer>
    <div className={styles.navigationContainer}>
      <ContentContainer>
        <Logo />

        <nav className={styles.navItemContainer}>
          {navigationItems
            .map(({ isShownOnMenu = true, path, label }: INavigationItem): ReactElement | null=> {
              if (!isShownOnMenu) {
                return null;
              }

              return (
                <RouteAwareLink
                  color="default"
                  key={path}
                  href={path}
                >
                  {label}
                </RouteAwareLink>
              );
            })}
        </nav>
      </ContentContainer>
    </div>

    <div className={styles.address}>
      <ContentContainer>
        <Typography variant="caption1">
          {texts.footer.address}
        </Typography>
      </ContentContainer>
    </div>
  </footer>
);

export default Footer;
