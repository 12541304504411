import { ReactElement, ReactNode } from 'react';
import { GlobalConfig as GlobalConfigBase } from '@interdan/public-ui';

import ErrorBoundary from './ErrorBoundary';
import styles from './styles.module.scss';

export interface IGlobalConfigProps {
  children: ReactNode;
}

const GlobalConfig = ({ children }: IGlobalConfigProps): ReactElement => (
  <GlobalConfigBase
    theme={styles.serviceBooker}
  >
    <ErrorBoundary>
      {children}
    </ErrorBoundary>
  </GlobalConfigBase>
);

export default GlobalConfig;
