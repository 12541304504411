import React, { ReactElement } from 'react';

import styles from './styles.module.scss';

const LocationOnFilledIcon = (): ReactElement => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.7286 0.984375C9.97876 0.984375 5.30078 5.66235 5.30078 11.4122C5.30078 18.6392 15.7389 30.9844 15.7389 30.9844C15.7389 30.9844 26.1565 18.2838 26.1565 11.4122C26.1565 5.66235 21.4787 0.984375 15.7286 0.984375ZM18.8749 14.4655C18.0074 15.3329 16.8681 15.7667 15.7286 15.7667C14.5894 15.7667 13.4497 15.3329 12.5825 14.4655C10.8476 12.7308 10.8476 9.90802 12.5825 8.17309C13.4226 7.33264 14.5401 6.86975 15.7286 6.86975C16.9172 6.86975 18.0345 7.33282 18.8749 8.17309C20.6099 9.90802 20.6099 12.7308 18.8749 14.4655Z"
      className={styles.accent}
    />
  </svg>

);

export default LocationOnFilledIcon;
