import { ReactElement, ReactNode } from 'react';

import CookieConsent from '^components/common/Layout/CookieConsent';

import Header from './Header';
import Footer from './Footer';
import styles from './styles.module.scss';

interface ILayoutProps {
  children: ReactNode;
}

const Layout = ({ children }: ILayoutProps): ReactElement => (
  <div className={styles.root}>
    <Header />

    <main className={styles.main}>
      {children}
    </main>

    <Footer />

    <CookieConsent />
  </div>
);

export default Layout;
