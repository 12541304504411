const texts = {
  pages: {
    notFound: {
      title: 'Not found',
    },
    home: {
      menuItemTitle: 'Forside',
      header: 'Det bliver ikke lettere at få serviceret din bil',
      title: 'Start her ved at indtaste din nummerplade',
      description: 'Servicebooker er en online bookingtjeneste, hvor du døgnet rundt kan bestille en værkstedstid til din bil på et autoriseret værksted for Peugeot, Citroën, DS, Opel eller Mitsubishi. Flere af vores værksteder udfører også service og reparationer på andre bilmærker.',
      howWeWorkTitle: 'Sådan fungerer Servicebooker',
      disclaimer: 'Bookingtjenesten er udviklet og ejet af',
      disclaimerSecondPart: 'med henblik på at gøre bookingprocessen mere kundevenlig end nogensinde.',
      companyName: ' K.W. Bruun ',

      cards: {
        plateNumber: 'Indtast din nummerplade',
        location: 'Vælg dit foretrukne værksted',
        eventNote: 'Book din næste værkstedsaftale',
        howToReg: 'Bookingbekræftelse fremsendes',
      },
    },
    aboutUs: {
      title: 'Om os',
      menuItemTitle: 'Om os',
      sections: [
        {
          title: 'Hvad er Servicebooker?',
          text: 'Servicebooker er en online bookingtjeneste, hvor du døgnet rundt kan bestille en værkstedstid til din bil på et autoriseret værksted. Indtast din nummerplade, vælg hvad der skal laves, og find en tid der passer dig - Så nemt er det.',
        },
        {
          title: 'Bevar garantien på din bil med Servicebooker',
          text: 'Hos Servicebooker er du altid sikret, at din garanti bevares. Vores autoriserede værksteder har direkte adgang til fabrikkens forskrifter, der benyttes kun originale reservedele, arbejdet udføres altid af mærkeuddannede mekanikere, og du får et autoriseret mærke-stempel i bilens servicebog.',
        },
      ],
      brandsTitle: 'Forhandlerne er autoriserede i følgende mærker:',

      bullets: [
        'Peugeot',
        'Opel',
        'Citroën',
        'DS',
        'Mitsubishi',
        'Fiat',
        'Jeep',
        'Alfa Romeo',
      ],
      behindServiceBookerSectionTitle: 'Hvem står bag Servicebooker?',
      behindServiceBookerSectionDescription: 'Servicebooker er udviklet af Auto.nu som er en del af K.W. Bruun Koncernen. K.W. Bruun står bag en lang række bilorienterede aktiviteter, herunder import og salg af person- og varebiler samt reservedele og tilbehør.',
      behindServiceBookerSectionDescriptionSecondPart: 'K.W. Bruun er en af Nordens største bilimportører og importerer nogle af Danmarks mest populære bilmærker bl.a. Peugeot, Citroën og Opel. Vi beskæftiger i dag ca. 500 medarbejdere, og har kontorer i København, Glostrup og Malmö samt reservedelslagre i hhv. Greve i Danmark og Strängnäs i Sverige.',

    },
    dataPolicy: {
      menuItemTitle: 'Persondatapolitik',
      title: 'Persondatapolitik',
      sections: [
        {
          title: 'Identitet og kontaktoplysninger:',
          text: 'K.W. Bruun Import A/S',
        },
        {
          title: '',
          text: `Hovedvejen 1
            2600 Glostrup
            CVR: 63557218
            GDPR@interdan.dk`,
        },
        {
          title: 'Introduktion:',
          text: `Vi behandler de oplysninger du indtaster i bookingformularen på Servicebooker.dk, herunder navn,
            adresse, e-mailadresse og telefonnummer, efter den til enhver tid gældende persondatalovgivning.`,
        },
        {
          title: 'Kategorier af personoplysninger:',
          text: 'I denne proces behandler vi udelukkende almindelige personoplysninger.',
        },
        {
          title: 'Formål:',
          text: `Formålet med behandlingen er at formidle din booking til forhandlerens bookingsystem, således at du
                kan komme på valgte værksted på det valgte tidspunkt.`,
        },
        {
          title: 'Retsgrundlag:',
          text: 'Behandlingsgrundlaget for denne behandling findes i Persondataforordningens artikel 6, stk. 1, litra f – vores legitime interesse i at behandle den registreredes oplysninger for at kunne informere forhandleren om den registreredes booking og derved gennemføre denne booking sammenholdt med den registreredes interesse i at oplysningerne ikke behandles.',
        },
        {
          title: 'Videregivelse:',
          text: 'Dine oplysninger videregives direkte til den forhandler du har valgt, forhandlerens behandling af personoplysninger finder du mere information om på forhandlerens hjemmeside.',
        },
        {
          title: 'Slettefrist:',
          text: `Data slettes efter 3 mdr. når det ikke har været brugt i 12 mdr.
                 Du kan som kunde have booket en service via vores hjemmeside.`,
        },
        {
          title: 'Dine rettigheder:',
          text: `Herunder kan du læse om dine rettigheder. Hvis du vil gøre brug af dine rettigheder,
                 kan du kontakte os via gdpr@interdan.dk.
                 Ret til indsigt: Du har ret til at vide hvilke personoplysninger, vi behandler om dig
                 samt få en række oplysninger om behandlingen.

                 Ret til berigtigelse: Du har ret til at få rettet urigtige oplysninger vi har om dig.

                 Ret til sletning: I nogle tilfælde har du ret til at få slettet oplysninger om dig
                 inden tidspunktet for vores almindelige generelle sletning indtræffer.

                 Ret til begrænsning af behandling: Du har ret til at få behandlingen af dine
                 personoplysninger begrænset.

                 Ret til indsigelse: Du har ret til at gøre indsigelse mod vores behandling af dine
                 personoplysninger.

                 Ret til dataportabilitet: Du har ret til at modtage dine personoplysninger i et
                 struktureret, almindeligt anvendt og maskinlæsbart format, samt at få overført disse
                 personoplysninger fra én dataansvarlig til en anden.

                 Klage til Datatilsynet: Du har ret til at indgive en klage til Datatilsynet, hvis du
                 er uenig i vores behandling af dine personoplysninger på. Du kan læse mere om, hvordan
                 du klager og finde Datatilsynets kontaktoplysninger på www.datatilsynet.dk.
                 Du kan læse mere om dine rettigheder på www.datatilsynet.dk.`,
        },
      ],
    },
  },
  footer: {
    address: 'K.W. Bruun Automotive A/S - Hovedvejen 1 - 2600 Glostrup - CVR: 63557218',
  },
};

export default texts;
