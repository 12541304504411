import React, { ReactElement } from 'react';

import ErrorIcon from '^icons/ErrorIcon';

import styles from './style.module.scss';

const ErrorPage = (): ReactElement => (
  <div className={styles.container}>
    <ErrorIcon />
  </div>
);

export default ErrorPage;
