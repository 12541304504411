import React, { ReactElement } from 'react';

import MainScreen from '^pages/Home/MainScreen';
import HowWeWork from '^pages/Home/HowWeWork';

import styles from './styles.module.scss';

const Home = (): ReactElement => (
  <div className={styles.main}>
    <MainScreen />

    <HowWeWork />
  </div>
);

export default Home;
