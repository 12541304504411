import { MouseEvent, ReactElement, useCallback } from 'react';
import { ILinkProps, Link } from '@interdan/public-ui';
import { useNavigate } from 'react-router-dom';

export interface IRouterAwareLinkProps extends ILinkProps {}

const RouterAwareLink = ({ href, onClick, ...rest }: IRouterAwareLinkProps): ReactElement => {
  const navigate = useNavigate();

  const handleClick = useCallback(
    (event: MouseEvent<HTMLAnchorElement>): void => {
      if (!href) {
        return;
      }

      if (typeof onClick === 'function') {
        onClick(event);
      }

      try {
        new URL(href);
      } catch (_) {
        event.preventDefault();

        navigate(href);
      }
    },
    [href, navigate, onClick],
  );

  return (
    <Link
      href={href}
      onClick={handleClick}
      {...rest}
    />
  );
};

export default RouterAwareLink;
