import { ReactElement } from 'react';

import GlobalConfig from '^components/GlobalConfig';
import RouterOutlet from '^routing/RouterOutlet';
import Router from '^routing/Router';

const App = (): ReactElement => (
  <GlobalConfig>
    <Router>
      <RouterOutlet />
    </Router>
  </GlobalConfig>
);

export default App;
