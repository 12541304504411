import { ReactElement, ReactNode } from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface IContentContainerProps {
  children: ReactNode;
  className?: string;
}

const ContentContainer = ({ children, className }: IContentContainerProps): ReactElement => (
  <div className={classNames(styles.content, className)}>
    {children}
  </div>
);

export default ContentContainer;
