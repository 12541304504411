import React, { ReactElement } from 'react';

import styles from './styles.module.scss';

const ErrorIcon = (): ReactElement => (
  <svg
    width="148"
    height="96"
    viewBox="0 0 148 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={styles.gray40}
      d="M48.2528 17.5871L34.8212 86.6442L33.0625 95.6847L122.73 95.244C126.753 95.2251 130.32 91.6506 131.615 86.3441L147.516 21.1464C148.499 17.1161 146.342 12.9264 143.288 12.9264L52.5337 13.0213C50.5281 13.0251 48.777 14.894 48.2528 17.5871Z"
    />

    <path
      className={styles.gray20}
      d="M114.13 81.9683L104.638 5.46231C104.251 2.32853 101.751 -0.0113541 98.8035 4.14439e-05L77.9838 0.068412C76.4416 0.0722105 74.964 0.729355 73.87 1.8917L63.9862 12.3984L6.08542 12.7023C2.549 12.7213 -0.170731 16.0678 0.296486 19.8321L9.11284 90.5415C9.50409 93.6715 11.9959 96.0076 14.9398 96L115.847 95.8025C127.087 96.0342 128.158 92.7181 128.158 92.7181C115.278 95.5669 114.13 81.9759 114.13 81.9683Z"
    />

    <path
      className={styles.white}
      d="M69.5512 45.6508L65.7907 42.2663L55.6339 53.1746L44.4088 43.0754L41.0054 46.7296L52.231 56.8293L42.0728 67.7392L45.8333 71.1198L55.9909 60.2122L67.2151 70.3107L70.6186 66.6566L59.3941 56.5578L69.5512 45.6508Z"
    />
  </svg>
);

export default ErrorIcon;
