import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import logger from '^utils/logger';

import App from './App';

import '^scss-global';

logger.init();

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById('root')!);

root.render((
  <StrictMode>
    <App />
  </StrictMode>
));
