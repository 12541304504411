import { ReactElement, ReactNode } from 'react';

import Title from '^components/common/Title';
import ContentContainer from '^components/common/ContentContainer';

import styles from './styles.module.scss';

interface IStaticPageTemplateProps {
  title: string;
  children: ReactNode;
}

const StaticPageTemplate = ({ title, children }: IStaticPageTemplateProps): ReactElement => (
  <ContentContainer className={styles.content}>
    <Title
      label={title}
      className={styles.title}
    />

    {children}
  </ContentContainer>
);

export default StaticPageTemplate;
