import { ReactElement, useMemo } from 'react';
import { Navigate, Outlet, useRoutes } from 'react-router-dom';
import { RouteObject } from 'react-router';

import {
  getAboutUsRoutePath,
  getDataPolicyRoutePath,
  getHomeRoutePath,
} from '^routing/helpers';
import Home from '^pages/Home';
import DataPolicy from '^pages/DataPolicy';
import AboutUs from '^pages/AboutUs';
import Layout from '^components/common/Layout';

const RouterOutlet = (): ReactElement => {
  const routes = useMemo(
    (): RouteObject[] => [
      {
        path: '',
        element: (
          <Layout>
            <Outlet />
          </Layout>
        ),
        children: [
          {
            path: getHomeRoutePath(),
            element: <Home />,
          },
          {
            path: getDataPolicyRoutePath(),
            element: <DataPolicy />,
          },
          {
            path: getAboutUsRoutePath(),
            element: <AboutUs />,
          },
          {
            path: '*',
            element: (
              <Navigate
                to={getHomeRoutePath()}
                replace
              />
            ),
          },
        ],
      },
    ],
    [],
  );

  return useRoutes(routes) as ReactElement;
};

export default RouterOutlet;

