import { ReactElement } from 'react';

import LogoIcon from '^icons/LogoIcon';
import { getHomeRoutePath } from '^routing/helpers';
import RouteAwareLink from '^components/common/RouteAwareLink';

const Logo = (): ReactElement => (
  <RouteAwareLink
    href={getHomeRoutePath()}
  >
    <LogoIcon />
  </RouteAwareLink>
);

export default Logo;
