import { ReactElement, useCallback, useState } from 'react';
import classNames from 'classnames';
import { useDeviceType } from '@interdan/public-ui';
import Hamburger from 'hamburger-react';

import ContentContainer from '^components/common/ContentContainer';
import Logo from '^components/common/Layout/Logo';
import getRootCssVariable from '^utils/getRootCssVariable';
import INavigationItem from '^types/INavigationItem';
import { navigationItems } from '^components/common/Layout/configs';
import useBodyScrollLock from '^hooks/useBodyScrollLock';
import RouteAwareLink from '^components/common/RouteAwareLink';

import styles from './styles.module.scss';

interface IHeaderProps {
  className?: string;
}

const HAMBURGER_SIZE = 24;
const HAMBURGER_COLOR = getRootCssVariable('--theme-color-white');

const Header = ({ className }: IHeaderProps): ReactElement => {
  const { isDesktop, isTablet, isMobile } = useDeviceType();

  const isDesktopOrTablet = isTablet || isDesktop;

  const [isMenuVisible, setMenuVisibility] = useState(false);

  const handleCloseMenu = useCallback(
    (): void => {
      setMenuVisibility(false);
    },
    [],
  );

  useBodyScrollLock(isMenuVisible);

  return (
    <header className={styles.headerContainer}>
      <ContentContainer className={styles.content}>
        <div className={classNames(styles.header, className)}>
          <Logo />

          {isMobile && (
            <div className={styles.hamburgerContainer}>
              <Hamburger
                color={HAMBURGER_COLOR}
                size={HAMBURGER_SIZE}
                toggled={isMenuVisible}
                toggle={setMenuVisibility}
              />
            </div>
          )}

          {((isMobile && isMenuVisible) || isDesktopOrTablet) && (
            <nav className={styles.navigationContainer}>
              {navigationItems
                .map(({ isShownOnMenu = true, path, label }: INavigationItem): ReactElement | null => {
                  if (isDesktopOrTablet && !isShownOnMenu) {
                    return null;
                  }

                  return (
                    <RouteAwareLink
                      color="default"
                      key={path}
                      href={path}
                      onClick={handleCloseMenu}
                    >
                      {label}
                    </RouteAwareLink>
                  );
                })}
            </nav>
          )}
        </div>
      </ContentContainer>
    </header>
  );
};

export default Header;
