import INavigationItem from '^types/INavigationItem';
import texts from '^configs/texts';
import { getAboutUsRoutePath, getDataPolicyRoutePath, getHomeRoutePath } from '^routing/helpers';

export const navigationItems: INavigationItem[] = [
  {
    label: texts.pages.home.menuItemTitle,
    path: getHomeRoutePath(),
    isShownOnMenu: false,
  },
  {
    label: texts.pages.dataPolicy.menuItemTitle,
    path: getDataPolicyRoutePath(),
  },
  {
    label: texts.pages.aboutUs.menuItemTitle,
    path: getAboutUsRoutePath(),
  },
];

