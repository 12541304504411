import { ReactElement } from 'react';
import { Typography } from '@interdan/public-ui';

import ServiceBookerWidget from '^components/ServiceBookerWidget';
import texts from '^configs/texts';
import ContentContainer from '^components/common/ContentContainer';
import Cards from '^pages/Home/Cards';

import styles from './styles.module.scss';

const HowWeWork = (): ReactElement => (
  <section className={styles.section}>
    <ServiceBookerWidget />

    <ContentContainer className={styles.content}>
      <Typography
        variant="subtitle3"
        className={styles.title}
      >
        {texts.pages.home.howWeWorkTitle}
      </Typography>

      <Cards />

      <Typography
        className={styles.disclaimer}
        variant="caption1"
      >
        {texts.pages.home.disclaimer}

        <span className={styles.company}>
          {texts.pages.home.companyName}
        </span>

        {texts.pages.home.disclaimerSecondPart}
      </Typography>
    </ContentContainer>
  </section>
);

export default HowWeWork;
