import RoutePath from '^enums/RoutePath';

/**
 * Root
 */
export const getHomeRoutePath = (): string => `/${RoutePath.Root}`;

/**
 * Data policy
 */
export const getDataPolicyRoutePath = (): string => `/${RoutePath.DataPolicy}`;

/**
 * About us
 */
export const getAboutUsRoutePath = (): string => `/${RoutePath.AboutUs}`;

/**
 * Error
 */
export const getErrorRoutePath = (): string => `/${RoutePath.Error}`;

